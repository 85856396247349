/* eslint-disable no-underscore-dangle */
import React from "react";
import PropTypes from "prop-types";
import {
  isSSR,
  isDebugModeActive,
  isDebugModeVisible,
  annotateAndLogError,
  getSanitizedError,
} from "@front10/utils";
import baseTheme from "./baseTheme.json";
import { CriticalError, ThemeProvider } from "@front10/base-elements";

/**
 * Patch to keep the SSR generated errors in client side
 * @param {*} cmpConfig
 */
const getSSRDebugError = (cmpConfig) => {
  if (!isSSR() && cmpConfig && cmpConfig.__DomNodeContainer) {
    const debugHelperNode = cmpConfig.__DomNodeContainer.querySelector(
      ".fr-debug-helper"
    );
    if (debugHelperNode) {
      return debugHelperNode.innerHTML;
    }
    return false;
  }
  return false;
};

const getTraceInfo = (cmpConfig) => {
  const err = {
    message: "TRACING",
  };
  const annotatedFakeError = annotateAndLogError(
    err,
    `INTEGRATION_CONNECTOR`,
    cmpConfig,
    true
  );
  const traceInfo = getSanitizedError(annotatedFakeError);
  return traceInfo;
};

/**
 * Component to render Raw HTML
 * @param {*} cmpConfig
 */
// eslint-disable-next-line react/prop-types
const RenderHTMLContent = ({ content }) => {
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

class IntegrationConnector extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    if (isSSR()) this.state = { rendered: true };
    else this.state = { rendered: false };
  }

  componentDidMount = () => {
    // TODO: Improving to fix
    this.setState({ rendered: true });
  };

  renderContent = () => {
    const {
      cmpConfig: { containerConfig },
      cmpConfig,
    } = this.props;
    const {
      settings: { instance: ContainerComponent, meta },
      settings,
      criticalError,
    } = containerConfig;

    const debugMode = cmpConfig.debugMode || containerConfig.debugMode;
    const traceMode = cmpConfig.traceMode || containerConfig.traceMode;

    const SSR_ERROR_HTML = getSSRDebugError(cmpConfig);
    let traceInfo = {};
    if (traceMode) {
      traceInfo = getTraceInfo(cmpConfig);
    }

    const theme = settings.theme || {};
    baseTheme.colors = theme.palette;
    baseTheme.text = theme.designTokens?.eds?.typo;

    return (
      <ThemeProvider theme={baseTheme}>
        <ThemeProvider theme={theme.designTokens}>
          <>
            {this.state.rendered &&
              criticalError &&
              isDebugModeActive(debugMode) &&
              !SSR_ERROR_HTML && (
                <div>
                  <CriticalError content={criticalError} meta={meta} />
                </div>
              )}
            {this.state.rendered &&
              !criticalError &&
              traceMode &&
              isDebugModeActive(debugMode) &&
              !SSR_ERROR_HTML && (
                <div>
                  <CriticalError content={traceInfo} meta={meta} />
                  <ContainerComponent {...settings} />
                </div>
              )}
            {this.state.rendered &&
              !criticalError &&
              !traceMode &&
              (!SSR_ERROR_HTML || !isDebugModeActive(debugMode)) && (
                <ContainerComponent {...settings} meta={meta} />
              )}
            {this.state.rendered && SSR_ERROR_HTML && isDebugModeVisible() && (
              <section>
                <RenderHTMLContent content={SSR_ERROR_HTML} />
                <ContainerComponent {...settings} />
              </section>
            )}
            {this.state.rendered && SSR_ERROR_HTML && !isDebugModeVisible() && (
              <div>
                <ContainerComponent {...settings} />
              </div>
            )}
          </>
        </ThemeProvider>
      </ThemeProvider>
    );
  };

  render = () => <div className="ic">{this.renderContent()}</div>;
}

IntegrationConnector.propTypes = {
  cmpConfig: PropTypes.objectOf(PropTypes.any),
  debugMode: PropTypes.bool,
};

IntegrationConnector.defaultProps = {
  cmpConfig: {},
  debugMode: false,
};

export default IntegrationConnector;
