import { AbstractContainer } from "@front10/abstract";
import preloadCustomProps from "../../em-ecosystem/utils/preloads/preloadStaticContainerProps";
import deepmerge from "../../em-ecosystem/utils/deepmerge";

const cmpConfig = {
  name: "abstract",
  moduleId: "em-cmp-lib-airmodules",
  containerConfig: {
    settings: {
      instance: AbstractContainer,
    },
  },
  hooks: {
    params: {},
    preloadCustomProps,
  },
};

const finalConfig = deepmerge(cmpConfig, {});

export default finalConfig;
