const registerComponent = (componentDefinitionInstance, cmpConfig) => {
  if (window && window.FC && window.FC.registerComponent) {
    window.FC.registerComponent(componentDefinitionInstance, cmpConfig);
  } else {
    console.log(
      "ERROR: window.FC.registerComponent not available in global scope, this will mean that your Component is not going to load properly"
    );
  }
};

export { registerComponent };
